import React, { useState, useEffect } from 'react';
import './App.css';
import Cookies from 'universal-cookie';
import {
  AddJobModal, JobList, LoginModal, Navbar,
} from './components';

const cookies = new Cookies();

function App() {
  const [id, setId] = useState(cookies.get('id'));
  const [jobs, setJobs] = useState([]);
  const [showAddJob, setShowAddJob] = useState(false);
  const url = 'https://api.justinchang.dev:4000';

  const loadJobs = () => {
    fetch(`${url}/api/users/jobs/${id}`).then((r) => r.json()).then((r) => {
      setJobs(r.data);
    });
  };

  useEffect(() => {
    if (id) {
      loadJobs();
    }
  }, [id]);

  return (
    <div className="App">
      <Navbar cookies={cookies} id={id} setShowAddJob={setShowAddJob} setId={setId} />
      <LoginModal cookies={cookies} url={url} id={id} setId={setId} />
      <AddJobModal
        show={showAddJob}
        setShow={setShowAddJob}
        loadJobs={loadJobs}
        id={id}
        url={url}
      />
      <JobList url={url} jobs={jobs} loadJobs={loadJobs} />
    </div>
  );
}

export default App;
