import React from 'react';
import {
  Bar, Button, ButtonContainer, GridContainer, Logo, LogoImg,
} from './Navbar.styles';

const Navbar = (props) => {
  const handleLogout = () => {
    props.cookies.remove('id');
    window.location.reload(false);
  };

  return (
    <Bar>
      <GridContainer>
        <Logo>
          <LogoImg src="./wibble.png" alt="logo" />
          Wibble
        </Logo>
      </GridContainer>
      <GridContainer />
      <ButtonContainer>
        {props.id ? (
          <>
            {/* <Button>
              <FontAwesomeIcon icon={faFilter} />
            </Button> */}
            <Button onClick={() => props.setShowAddJob(true)}>
              +
            </Button>
            <Button onClick={handleLogout}>
              Logout
            </Button>
          </>
        ) : null}
      </ButtonContainer>
    </Bar>
  );
};

export { Navbar };
