import React, { useState, useEffect } from 'react';
import {
  Form, FormButtonContainer, FormTextArea, Modal, ModalInner, FormButton,
} from './Modal.styles';
import '../../styles/animations.css';

const AddJobModal = (props) => {
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [link, setLink] = useState('');
  const [shake, setShake] = useState(false);

  const shakeModal = () => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 500);
  };

  useEffect(() => {
    setCompany('');
    setPosition('');
    setLink('');
  }, [props.show]);

  const settingsPost = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      job: {
        user_id: props.id,
        company,
        position,
        link,
        status: 'Applied',
      },
    }),
  };

  const handleClose = (e) => {
    e.preventDefault();
    props.setShow(false);
  };

  const handleAddJob = (e) => {
    e.preventDefault();
    if (!company || !position) {
      shakeModal();
      return;
    }
    fetch(`${props.url}/api/jobs`, settingsPost).then((r) => r.json()).then((r) => {
      if (!r.errors) {
        props.loadJobs();
        handleClose(e);
      } else {
        shakeModal();
      }
    });
  };

  const handleCompanyInputChange = (e) => setCompany(e.target.value);
  const handlePositionInputChange = (e) => setPosition(e.target.value);
  const handleLinkInputChange = (e) => setLink(e.target.value);

  return (
    <>
      {props.show ? (
        <Modal>
          <ModalInner className={shake ? 'shake' : ''}>
            <Form>
              <FormTextArea
                tabIndex={0}
                placeholder="Company"
                type="text"
                onChange={handleCompanyInputChange}
              />
              <FormTextArea
                tabIndex={0}
                placeholder="Position"
                type="text"
                onChange={handlePositionInputChange}
              />
              <FormTextArea
                tabIndex={0}
                placeholder="Link (optional)"
                type="text"
                onChange={handleLinkInputChange}
              />
              <FormButtonContainer>
                <FormButton
                  onClick={handleAddJob}
                >
                  Add Job
                </FormButton>
                <FormButton
                  onClick={handleClose}
                >
                  Cancel
                </FormButton>
              </FormButtonContainer>
            </Form>
          </ModalInner>
        </Modal>
      ) : null}
    </>
  );
};

export { AddJobModal };
