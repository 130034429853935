import React, { useState, useEffect } from 'react';
import {
  Modal, ModalInner, Form, FormTextArea, FormButton, FormButtonContainer,
} from './Modal.styles';
import '../../styles/animations.css';

const LoginModal = (props) => {
  const [show, setShow] = useState(!props.cookies.get('id'));
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [shake, setShake] = useState(false);

  useEffect(() => {
    setShow(!props.cookies.get('id'));
  }, [props.cookies]);

  const shakeModal = () => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 500);
  };

  const settingsPostSignUp = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user: {
        username,
        password,
      },
    }),
  };

  const settingsPostLogin = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  };

  const handleResponse = (bool, id) => {
    if (bool) {
      shakeModal();
    } else {
      props.cookies.set('id', id, { path: '/' });
      props.setId(id);
      setShow(false);
    }
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    fetch(`${props.url}/api/users`, settingsPostSignUp).then((r) => r.json()).then((r) => {
      handleResponse(r.errors, r.errors ? null : r.data.id);
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    fetch(`${props.url}/api/users/login`, settingsPostLogin).then((r) => r.json()).then((r) => {
      handleResponse(!r.success, r.id);
    });
  };

  const handleUsernameInputChange = (e) => setUsername(e.target.value);
  const handlePasswordInputChange = (e) => setPassword(e.target.value);

  return (
    <>
      {show ? (
        <Modal>
          <ModalInner className={shake ? 'shake' : ''}>
            <Form>
              <FormTextArea
                tabIndex={0}
                placeholder="Username"
                type="text"
                onChange={handleUsernameInputChange}
              />
              <FormTextArea
                tabIndex={0}
                placeholder="Password"
                type="password"
                onChange={handlePasswordInputChange}
              />
              <FormButtonContainer>
                <FormButton
                  onClick={handleSignUp}
                >
                  Sign Up
                </FormButton>
                <FormButton
                  onClick={handleLogin}
                >
                  Login
                </FormButton>
              </FormButtonContainer>
            </Form>
          </ModalInner>
        </Modal>
      ) : null}
    </>
  );
};

export { LoginModal };
