import React from 'react';
import { Job } from './Job';
import { JobsContainer } from './JobList.styles';

const JobList = (props) => {
  const props2 = props;
  return (
    <JobsContainer v={props2}>
      {props.jobs ? props.jobs.map((j) => <Job url={props.url} job={j} loadJobs={props.loadJobs} />)
        : null}
    </JobsContainer>
  );
};

export { JobList };
