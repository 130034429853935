import styled from 'styled-components';

export const Bar = styled.div`
  display: grid;
  grid-template-columns: 2fr 12fr 2fr;
  grid-template-rows: 1fr;
  min-height: 66px;
  max-height: 6vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  position:fixed;
  background-color: #3a284a;
  z-index: 1;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`;

export const Button = styled.div`
  max-width: 100%;
  font-size: 1em;
  border-radius: 5px;
  border: 0px solid #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #4d3561;
  color: white;
  text-align: center;
  padding: 1em;
  margin: 0.5em;
  cursor: pointer;
  &:hover {
    background-color: #aec4d4;
    color: black;
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-height: 100%;
  color: white;
  font-size: 2em;
`;

export const LogoImg = styled.img`
  height: 1.25em;
  margin-left: 0.5em;
  margin-right: 0.25em;
`;
