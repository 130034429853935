import styled from 'styled-components';

export const JobContainer = styled.div`
  font-family: "Poppins", sans-serif;
  border: 0px solid;
  border-radius: 3vh;
  border-color: white;
  box-shadow: 0 0 1vh 1vh rgb(0 0 0 / 8%);
  width: min(1600px, 70%);
  height: 6vh;
  padding-left: 2%;
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 2fr 4fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  grid-template-rows: 1fr;
`;

export const JobCompany = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

export const JobPosition = styled.div`
  display: flex;
  align-items: center;
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #aec4d4;
    color: black;
  }
`;

export const SelectedStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: #4d3561;  
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #aec4d4;
    color: black;
  }
`;

export const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-radius: 0px 3vh 3vh 0px;
  &:hover {
    background-color: red;
    color: white;
  }
`;

export const RedirectIcon = styled.div`
  margin-left: 0.5em;
  cursor: pointer;
`;
