import { faExternalLinkAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import {
  JobContainer, JobCompany, SelectedStatus, Status, JobPosition, RedirectIcon, DeleteButton,
} from './Job.styles';

const Job = (props) => {
  const [status, setStatus] = useState(props.job.status);
  const [show, setShow] = useState(true);

  const statuses = ['Applied', 'OA', 'Phone', 'Final', 'Offer', 'Rejected', 'Delete'];

  const settingsPutStatus = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      job: {
        status,
      },
    }),
  };

  const settingsDelete = {
    method: 'DELETE',
  };

  const handleDelete = () => {
    setShow(false);
    fetch(`${props.url}/api/jobs/${props.job.id}`, settingsDelete).then(() => {
      props.loadJobs();
    });
  };

  useEffect(() => {
    if (status) {
      fetch(`${props.url}/api/jobs/${props.job.id}`, settingsPutStatus);
    }
  }, [status]);

  const openLink = () => {
    if (props.job.link) {
      window.open(props.job.link);
    }
  };

  return (
    <>
      {show ? (
        <JobContainer>
          <JobCompany onClick={openLink}>
            {props.job.company}
            {props.job.link
              ? (
                <RedirectIcon>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </RedirectIcon>
              ) : null}
          </JobCompany>
          <JobPosition>
            {props.job.position}
          </JobPosition>
          {statuses.map((s) => {
            if (s === status) {
              return <SelectedStatus>{s}</SelectedStatus>;
            } if (s === 'Delete') {
              return (
                <DeleteButton onClick={() => handleDelete()}>
                  <FontAwesomeIcon icon={faTimes} />
                </DeleteButton>
              );
            }
            return <Status onClick={() => setStatus(s)}>{s}</Status>;
          })}
        </JobContainer>
      ) : null}
    </>
  );
};

export { Job };
