import styled from 'styled-components';

export const JobsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 90vh;
  padding-top: max(80px, 8vh);
  text-align: left;
`;
