import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0, 0, 0, 0.9);
  transition: opacity 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalInner = styled.div`
  transition: top 0.25s ease;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 30%;
  margin: auto;
  background: white;
  border-radius: 5px;
  padding: 4em;
  max-height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 98.5%;
  font-family: "Poppins", sans-serif;
`;

export const FormTextArea = styled.input`
  padding: 1em;
  margin: 0.5em;
  font-size: 1em;
  border-radius: 5px;
  border: 2px solid #ccc;
  width: 75%;
`;

export const FormButtonContainer = styled.div`
  margin: 0.5em;
  width: 75%;

  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

export const FormButton = styled.div`
  font-family: "Poppins", sans-serif;
  width: 250px;
  font-size: 1em;
  border-radius: 5px;
  border: 0px solid #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #3a284a;
  color: white;
  text-align: center;
  width: 100%;
  padding: 1em;
  margin: 0.5em;
  cursor: pointer;
`;
